<template lang="pug">
span
  <!-- p {{ previewImage }} -->
  slot(name="image" :toggle="toggle" :openFileLoader="openFileLoader")
    base-input-file(ref="baseInput", :disabled="disabled" :loading="loading", @input="showFileInfo")
      template(#icon v-if="!$scopedSlots.image")
        slot(name="activator" :showFileInfo="showFileInfo" :hasCoursePhoto="hasCoursePhoto" :toggle="toggle")
          v-icon.pointer(v-if="!hasCoursePhoto") mdi-file-image-plus
          v-icon.pointer(v-else, color="success") mdi-file-image
  slot(
    :openFileLoader="openFileLoader"
    :showFileInfoWithoutToggle="showFileInfoWithoutToggle"
    :updateShowing="updateShowing"
    :showing="showing" :loadFile="loadFile" :toggle="toggle" :close="close" :removePhoto="removePhoto" :previewImage="previewImage" :loading="loading")
    app-dialog(v-model="showing", @input="close")
      template(#title) 
        span.image-title
          | You are uploading a photo for
          br
          span.image-title__fullname {{ fullname }}
      template(#dialog-body)
        base-preloader(v-if="loading && !previewImage")
        img.preview-img(:src="previewImage")

      template(#dialog-footer)
        v-spacer
        app-button.mr-3(
          color="#f4f7f9",
          :loading="loading",
          hide-shadow,
          @click.native="close"
        )
          span.text-label Cancel
        app-button.mr-3(
          color="#f4f7f9",
          :loading="loading",
          hide-shadow,
          @click.native="loadFile"
        )
          span.text-label Upload
</template> 

<script>
import errorsMixin from "@/mixins/errors.mixin";
import showingMixin from "@/mixins/showing.mixin";
import heic2any from "heic2any";

export default {
  name: "UploadPhoto",

  inject: ["candidatesActionsSvc"],

  mixins: [errorsMixin, showingMixin],

  props: {
    ID: { type: Number, required: true },
    coursePhoto: { type: String, default: "" },
    fullname: String,
    disabled: Boolean
  },

  computed: {
    hasCoursePhoto() {
      return !!(this.coursePhoto || this.hasPhoto);
    },
  },

  data: () => ({
    loading: false,
    hasPhoto: null,
    previewImage: null,
    file: null,
  }),

  methods: {
    async showFileInfo(value) {
      this.toggle();
      this.loading = true;
      let fileToPreview = await this.fileToPreview(value);
      this.loading = false;
      this.previewImage = URL.createObjectURL(fileToPreview);
      this.file = value;
    },

    async showFileInfoWithoutToggle(value) {
      this.loading = true;
      let fileToPreview = await this.fileToPreview(value);
      this.loading = false;
      this.previewImage = URL.createObjectURL(fileToPreview);
      this.file = value;
    },

    async fileToPreview(file) {
      if (!/heic/.test(file.type)) return file;
      try {
        const blob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.94,
        });
        return new File([blob], "heicFile");
      } catch (error) {
        return file;
      }

      // The editor expects a File so let's convert our Blob
    },

    close() {
      this.updateShowing(false);
      this.clear();
    },

    async loadFile() {
      await this.inputFile(this.file);
      this.close();
    },

    async inputFile(value) {
      try {
        this.$emit('loading:start')
        this.loading = true;
        let res = await this.candidatesActionsSvc().uploadCoursePhoto(
          [this.ID],
          value
        );
        if (res && res[0] && res[0].course_photo) this.hasPhoto = true;
        this.clear();
        this.$notify({ text: "Successfully uploaded", type: "success" });
      } catch (error) {
        console.log(error);
        this.processError(error);
      }

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    async removePhoto() {
      this.previewImage = null;
      this.file = null;
      this.toggle()
      if(!this.coursePhoto) return
      this.$emit('remove:photo')
      await this.candidatesActionsSvc().removeCoursePhoto(
        this.ID,
      );
      
      
      
    },

    openFileLoader() {
      this.$refs.baseInput.toggle()
    },

    clear() {
      if(this.$refs.baseInput ) this.$refs.baseInput.clear();
      this.previewImage = null;
      this.file = null;
    },
  },

  components: {
    baseInputFile: () =>
      import("@/components/global/actions/BaseInputFile.vue"),
    appButton: () => import("@/components/global/actions/BaseButton.vue"),
    appDialog: () => import("@/components/global/actions/BaseDialog.vue"),
    BasePreloader: () => import("@/components/global/BasePreloader.vue"),
  },
};
</script>

<style lang="scss" scoped>
.preview-img {
  width: 100%;
}

.image-title {
  font-weight: 400;
  &__fullname {
    font-weight: 700;
    font-size: 24px;
  }
}

</style>
