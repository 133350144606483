<template lang="pug">
full-screen-modal(
  v-model="showing"
  title="Sorting"
  no-body-spacing
)
  template(#activator)
    
    v-icon(@click="toggleModal" :class="{'filters-selected': sortBy.length}") mdi-filter-variant
  div.filters
    sorting-list(
      :sortingList="sortingList"
      :sortBy.sync="temp.by" 
      :sortDesc.sync="temp.desc"
      
  )
    .filters__actions
      app-btn(color="negative" v2 font-size="16px" block @click.native="clear") CLEAR
      app-btn(color="primary" v2 font-size="16px" block @click.native="apply") APPLY

    
</template>

<script>
import showingMixin from "@/mixins/showing.mixin";
import sortingMixin from "@/mixins/table/sorting.mixin";
import FullScreenModal from "./CourseFullScreenModal.vue"
import SortingList from "./CourseDaySortingTemp.vue"
import AppBtn from '@/components/global/actions/BaseButton.vue'


export default {
  props: {sortBy: Array, sortDesc: Array},
  mixins: [showingMixin, sortingMixin],
  components: {
    FullScreenModal,
    SortingList,
    AppBtn
  },

  data: () => ({
    temp: {by: [], desc: []},
    sortingList: [
      {label: 'Name', value: 'candidateName'},
      {label: 'Presentie', value: 'presentie_status'},
      {label: 'Result', value: 'exam_result'},
    ]
  }),

  methods: {
    toggleModal() {
      if(!this.$route.query.course_day) return
      this.toggle()
    },
    clear() {
      this.$emit('update:sort-by', [])
      this.$emit('update:sort-desc', [])
      this.toggle()
    },

    apply() {
      this.$emit('update:sort-by', this.temp.by)
      this.$emit('update:sort-desc', this.temp.desc)
      this.toggle()
    },
  },

  watch: {
    showing: {
      immediate: true,
      handler: function () {
        if(this.showing) {
          this.temp.by = this.sortBy
          this.temp.desc = this.sortDesc
        }
      }
    }
  }
 
}
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #FFFFFF;

  &__actions {
    display: flex;
    margin-top: auto;
    .app-button {
      margin: 9px;
    }
  }
  &-selected {
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $primary-color;
      box-shadow: 0 0 0 3px #FFFFFF;
      content: "";
    }
  }
}

</style>

<style lang="scss">
  .filter-section {
    &__title {
      padding-left: 18px;
      color: #424C52;
      font-weight: 600;
      font-size: 12px;
    }
  }

  .sort-item {
    display: flex;
    align-items: center;
    padding: 18px;
    border-bottom: 1px solid #ECECEC;
    color: #232627;
    .sort-icon {
      margin-left: auto;
      &.desc {
        transform: rotate(180deg);
      }
    }
  }

</style>