<template lang="pug">
.course-group-wrapper
  .course-group(v-for="group in groupedList")
    .course-group__header 
      .course-group__title {{ group.label }}
      .course-group__sessions()
        .course-session(v-for="(session, index) in group.sections") {{ index === group.sections.length - 1 ? 'H': index + 1}}
    .course-group__list
      slot(:list="group.items" name="list")
  slot(name="footer")
</template>

<script>
export default {
  props: {
    groupedList: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.course-group {
  font-family: $font;

  &__header {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-left: 16px;
    background: #F5F5F5;
  }
  &__title {
    color: #232627;
    font-weight: 600;
    font-size: 14px;
  }
  &__list {
    
  }
  &__sessions {
    display: flex;
    margin-left: auto;
  }
}

.course-session {
  margin-right: 4px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: #5C6E77;
  color: #FFFFFF;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  
}


.course-group-wrapper {
  padding-bottom: 50px;
}
</style>