<template lang="pug">
  full-screen-modal( v-model="showModal" :title="title" no-body-spacing)
    template(v-slot:activator="{ on }")
      v-icon.change-color(v-on="on" data-test="change-color-btn" :disabled="disabled") mdi-format-color-fill
    template(v-slot:before-title v-if="editableColor")
      v-icon(slot="before-title" size="18" @click="cancel") mdi-arrow-left
    template(v-slot:actions)
      v-icon(color="primary" @click="onAdd") mdi-plus
    div
      div
        div.add-color(v-if="editableColor" data-test="add-edit-color")
         
          v-color-picker(mode="hexa" :width="375" hide-mode-switch v-model="editableColor.hex_color_code")
          app-input(
            type="number"
            placeholder="Order"
            v-model="editableColor.order"
            :error="formErrors.hasError('order')"
            :error-messages="formErrors.fields.order"
            label="Order"
          )
          app-input(
            placeholder="name"
            v-model="editableColor.name"
            :error="formErrors.hasError('name')"
            :error-messages="formErrors.fields.name"
            label="Name"
          )
          app-btn(color="primary" v2 font-size="16px" block @click.native="save") SAVE


          div.buttons
            <!-- v-btn.save-button(@click="save" color="primary" data-test="color-save-btn") Save
            v-btn.cancel-button(@click="cancel" data-test="color-cancel-btn") Cancel -->
        div.color-list(v-if="!editableColor")
          div.color-item
            .color-item__main-info(@click="clearColor")
              span.color-item__order.color-item__order_default A
              span.color-item__name No color (default)
          div.color-item(v-for="item in colors" :key="item.ID")
            .color-item__main-info(@click="onChooseColor(item)")
              span.color-item__order(:style="{'background-color': item.hex_color_code}") {{ item.order }}
              span.color-item__name {{ item.name }}
            <!-- div.color-item__nam(:style="{'background-color': item.hex_color_code}" @click="onChooseColor(item)" ) -->
            v-icon(@click="onEdit(item)" x-small :size="18").color-item__edit mdi-pencil-outline
        <!-- v-btn.add-button(v-if="!editableColor" color="primary" @click="onAdd" data-test="add-new-color-btn") Add New
        v-btn.clear-button(v-if="!editableColor" @click="clearColor") Clear Color -->
</template>

<script>
import FullScreenModal from "./CourseFullScreenModal.vue"

import { CandidateColor } from '@/app/admin/modules/candidates/core/models/candidateColorModel'
import ErrorMixin from "@/mixins/errors.mixin"
import FormErrors from "@/util/form-errors"
import { mapState, mapActions } from 'vuex'
import CandidateService from '@/app/admin/modules/candidates/core/candidates-service'
import apiCandidates from '@/services/api/api-candidates'
import AppBtn from '@/components/global/actions/BaseButton.vue'


let ise2e = process.env.NODE_ENV === 'e2e'

export default {
  name: 'CandidatesColor',

  mixins: [ErrorMixin],

  inject: ['svc'],

  props: {
    activeItems: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    page: { type: String, default: 'CANDIDATES' }
  },

  data: () => ({
    colorBeforeEdit: null,
    formErrors: new FormErrors(),
    showModal: false,
    editableColor: null,
    ise2e: ise2e
  }),

  computed: {
    ...mapState('crmCandidateColors', {
      _colors: state => state.colors
    }),

    colors() {
      return this._colors.filter(color => color.page.includes(this.page))
    },

    nextOrder() {
      return this.colors[this.colors.length - 1].order + 1
    },

    isEdit() {
      return this.editableColor && this.editableColor.id
    },

    title() {
      if(this.isEdit) return 'Edit color'
      else if(this.editableColor) return 'Add new'
      return 'Select color'
    }
  },

  watch: {
    showModal(value) {
      if (!value) this.cancel()
    }
  },

  methods: {
    ...mapActions({
      createColor: 'crmCandidateColors/createColor',
      updateColor: 'crmCandidateColors/updateColor',
      deleteColor: 'crmCandidateColors/deleteColor',
    }),

    validateOrderRequiredField() {
      this.formErrors.resetFields()
      this.formErrors.validField('order')
      if (!this.editableColor.order.toString().length) {
        this.formErrors.invalidField('order', 'Order is required field')
        return
      }
      if (!this.editableColor.name.toString().length) {
        this.formErrors.invalidField('name', 'Order is required field')
        return
      }
      this.validateOrderDuplicated();
    },

    validateOrderDuplicated() {
      const ordersList = this.isEdit ?
        this.colors.map(color => +color.order).filter(id => id !== +this.colorBeforeEdit.order) :
        this.colors.map(color => +color.order)

      if (ordersList.includes(+this.editableColor.order)) {
        this.formErrors.invalidField('order', 'Duplicated order')
      }
    },

    async onChooseColor(color) {
      const candidatesIds = this.activeItems.map(item => item.ID)
      await new CandidateService().saveColor(candidatesIds, color.id)
      this.showModal = false;
    },

    async clearColor() {
      const candidatesIds = this.activeItems.map(item => item.ID)
      await new CandidateService().saveColor(candidatesIds, null)
      this.showModal = false;
    },

    onAdd() {
      this.editableColor = new CandidateColor({ page: this.page, order: this.nextOrder.toString() })
    },

    onEdit(item) {
      this.editableColor = new CandidateColor({ page: this.page, ...item })
      this.colorBeforeEdit = new CandidateColor(item)
    },

    async save() {
      this.validateOrderRequiredField();

      if (!this.formErrors.isValid()) {
        return this.$notify({ type: 'error', text: 'invalid field' })
      }
      if (this.formErrors.isValid()) {
        if (this.isEdit) {
          try {
            await this.updateColor(this.editableColor)
            this.cancel()
          } catch (err) {
            this.processErrorWithForm(err, {
              redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
              formErrors: this.formErrors,
              apiAttributes: {
                order: 'order',
                hex_color_code: 'hex_color_code'
              },
            });
          }
        } else {
          try {
            await this.createColor(this.editableColor)
            this.cancel()
          } catch (err) {
            this.processErrorWithForm(err, {
              redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
              formErrors: this.formErrors,
              apiAttributes: {
                order: 'order',
                hex_color_code: 'hex_color_code'
              },
            });
          }
        }
      }
    },

    cancel() {
      this.editableColor = null;
    }
  },

  components:{
    FullScreenModal, 
    appInput: () => import('@/components/global/actions/BaseInput.vue'),
    AppBtn
  }
}
</script>

<style lang="scss" scoped>

.change-color {
  cursor: pointer;

  &-title {
    width: 100%;
    text-align: center;
  }
}

.back-icon {
  position: absolute;
  top: 18px;
  left: 16px;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 18px;
}

.add-color {
  background: #fff;
  padding: 18px;
}

.color-list {
  background: #fff;
  .color-item {
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 100%;
    padding: 18px;
    border-bottom: 1px solid #ECECEC;
    &__main-info {
      display: inline-flex;
      align-items: center;
    }
    &__order {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      font-weight: 700;
      color: #232627;
      border-radius: 50%;
      text-align: center;
      margin-right: 8px;
      &_default {
        border: 1px solid #ECECEC;
      }
    }
    &__edit {
      font-size: 18px !important;
    }
  }

}

.buttons {
  text-align: right;
}

.add-button {
  text-align: center;
  margin: 8px 0;
}

.clear-button {
  margin: 8px 4px;
}

.save-button, .cancel-button {
  text-align: center;
  margin-left: 4px;
  margin-top: 8px;
}

.margin-l-8 {
  margin-left: 8px;
}
</style>
