<template lang="pug">
.searching(:class="{'searching_open': showing}")
  
  .searching__activator(@click="startSearch")
    v-icon(:color="value ? '#000': ''") mdi-magnify
  app-input(
    :value="value"
    placeholder="..."
    @input="updateSearch"
    ref="input"
    text
    hide-details
  ).searching__field
  v-icon(v-if="showing" @click="clear").searching__clear mdi-close
</template>

<script>
import {debounce} from 'lodash';
import showingMixin from '@/mixins/showing.mixin'
import appInput from '@/components/global/actions/BaseInput'

export default {
  mixins: [showingMixin],

  data: () => ({
    search: undefined
  }),


  computed: {
    value() {
      return this.$route.query.search
    }
  },

  methods: {
    change() {
      this.$emit('change')
    },

    updateSearch: debounce(async function (value) {
      try {
        if (value === this.value) return
        await this.$router.push({ query: {...this.$route.query, page: 1, search: value || undefined }})
        this.change()
      } catch (error) {
        console.log(error)
      }
    }, 350),

    async updateDialogSearch() {
      await this.updateSearch(this.search)
      this.close()
    },

    open() {
      this.updateShowing(true)
    },

    close() {
      this.updateShowing(false)
    },

    reset() {
      this.search = undefined
    },

    clear() {
      this.updateSearch('')
      this.close()
    },

    async startSearch() {
      if(!this.showing) {
        this.open()
        await this.$nextTick();
        this.$refs.input.focus();
        return
      } 
      this.close();
    },

    prefill() {
      this.search = this.value;
    }
  },
  components: {
    appInput
  }
}
</script>

<style lang="scss" scoped>
.searching {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.3s ease;
  &_open {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #FFFFFF;

    .searching__field {
      width: 100%;
    }

    .searching__clear {
      opacity: 1;
    }
  }
  &__activator {
    margin-right: 5px;
    margin-left: 15px;
  }
  &__field {
    width: 0;
    transition: all 0.3s ease;
  }
  &__clear {
    display: inline-block;
    margin-right: 17px;
    opacity: 0;
    transition: all 0.3s ease;
  }
}

</style>