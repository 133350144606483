<template lang="pug">
  div
    div
      app-btn(
        v-if="reservedExam.result && btnResultLabel"
        @click.native="resultShowAndMarkSeen"
        :color="colorStatus"
        :border="PURPLE"
        data-test="results-show"
      ) 
        span.result-text-label {{ btnResultLabel }}
    div
      app-btn(
        v-if="canLoadData || statusLoaded || statusFailed"
        :color="PURPLE"
        @click.native="showModal"
        data-test="results-set"
        :loading="loading"
      ).text-capitalize.set-result Set result
    v-dialog(v-model="showing" max-width="500" scrollable)
      v-card(data-test="results-info-dialog").exam-card
        v-card-title.headline {{ candidateName }} results:
        v-divider
        v-card-text(v-if="result" data-test="edit-form").exam-card__container
          h3.form-title Main info
          p.text-box
            span.text-box__title Results:
            span.text-box__content
              v-select(
                data-test="results-field"
                v-model="result.results"
                :items="options"
                label="Choose result"
                :error="formErrors.hasError('results')"
                :error-messages="formErrors.fields.results"
              )
          p.text-box
            span.text-box__title Categorie:
            span.text-box__content {{ reservedExam.product_name }}
          p.text-box
            v-checkbox(
              v-model="result.has_zero_mistakes"
              label='Zero mistakes'
              hide-details
            )

          h3.form-title(v-if="mistake") Mistake:
          p.text-box(v-if="mistake")
            span.text-box__content(v-html="mistake" )
        v-card-actions
          v-spacer
          v-btn(data-test="save-btn" color="primary" @click="saveResults") save
          v-btn(text color="primary" @click="hideModal") close
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import ResultReservedExam, { ResultReservedExamFormValidationFields } from "@/app/admin/modules/results/core/models/resultReservedExam";
import { RESULT_TYPES, PURPLE } from '@/app/admin/modules/results/core/results-const'
import FormErrors from "@/util/form-errors"
import showingMixin from '@/mixins/showing.mixin';
import { RESULT_STATUS } from '@/app/admin/modules/results/core/models/resultReservedExam'
import { COLOR_STATUS_NEW as COLOR_STATUS } from '../../../results/core/results-const'
import AppBtn from '@/components/global/actions/BaseButton.vue'

export default {
  mixins: [errorsMixin, showingMixin],

  props: {
    reservedExam: {
      type: Object,
      required: true
    },

    candidateName: {
      type: String,
      default: 'name'
    },

  },

  inject: ['svc'],

  data: () => ({
    loading: false,
    PURPLE,
    options: Object.values(RESULT_TYPES),
    result: null,
    formErrors: null,
  }),

  mounted() {
    this.init()
  },

  computed: {
    btnResultLabel() {
      if (!this.reservedExam.result || !this.reservedExam.result.results[0]) return null
      return this.reservedExam.result.results[0].toUpperCase()
    },
    mistake() {
      if (this.statusInit) return null
      if (!this.reservedExam.result) return null
      return this.reservedExam.result.mistake.replace(/\n/g, '<br />')
    },
    canLoadData() {
      if (!this.reservedExam.result) return true
      return false
    },

    statusInit() {
      if (!this.reservedExam.result) return true
      return this.canBeLoaded
    },

    statusFailed() {
      if (this.reservedExam.result && this.reservedExam.result.status === RESULT_STATUS.FAILED) return true
      return false
    },

    statusLoaded() {
      if (this.reservedExam.result && this.reservedExam.result.status === RESULT_STATUS.FETCHING) return true
      return false
    },

    loadedSeen() {
      if (!this.reservedExam.result) return false
      return this.reservedExam.result.seen
    },

    colorStatus() {
      if (!this.reservedExam.result) return 'primary'
      if (!this.loadedSeen) return COLOR_STATUS.UNSEEN
      if (this.reservedExam.result && this.reservedExam.ignore_results) return COLOR_STATUS.IGNORE_RESUTLS
      if (this.reservedExam.result && this.reservedExam.result.has_zero_mistakes) return COLOR_STATUS.ZERO_MISTAKES
      if (this.reservedExam.result && this.reservedExam.result.has_passed) return COLOR_STATUS.HAS_PASSED
      if (this.reservedExam.result && this.reservedExam.result.has_not_came) return COLOR_STATUS.HAS_NOT_CAME
      if (this.reservedExam.result && !this.reservedExam.result.has_passed) return COLOR_STATUS.NOT_HAS_PASSED
      return null
    },
  },

  watch: {
    'showing'(value) {
      if (value) {
        this.init()
      }
    }
  },

  methods: {
    init() {
      this.resetErrors()
      this.result = this.reservedExam.result ? new ResultReservedExam({
        ...this.reservedExam.result,
        has_zero_mistakes: this.reservedExam.result.has_zero_mistakes
      }) : new ResultReservedExam({})
    },
    resetErrors() {
      this.formErrors = new FormErrors();
    },

    async saveResults() {
      try {
        this.loading = true
        await this.svc.saveResults(this.reservedExam.id, this.result.getApiData())
        this.hideModal()
      } catch (error) {
        console.log(error, 'err')
        this.processErrorWithForm(error,
                                  {
                                    formErrors: this.formErrors,
                                    redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
                                    apiAttributes: new ResultReservedExamFormValidationFields()
                                  }
        )
      } finally {
        this.loading = false
      }
    },

    resultShowAndMarkSeen() {
      this.showModal()
      this.markSeen()
    },

    async markSeen() {
      try {
        await this.svc.markSeen(this.reservedExam.id)
        this.reservedExam.result.seen = true
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    showModal() {
      this.showing = true
    },
    hideModal() {
      this.showing = false
    },
  },

  components:{ AppBtn}
}
</script>

<style lang="scss" scoped>
.text-box {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 5px;

  &__content {
    margin-left: 8px;
    color: #000000;
  }
}

</style>

<style lang="scss">
.exam-card {
  &__container {
    padding-top: 10px !important;
  }
}

.set-result .app-button__wrapper {
  color: #FFFFFF;
}

</style>


<style lang="scss" scoped>

.result-text-label {
  color: #7E1E64;
}

</style>