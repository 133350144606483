export default {
  data: () => ({
    expanded: [],
  }),

  methods: {
    hideExpandBy(expand) {
      expand(false)
    },

    updateTableDataBy(item, candidate) {
      item.merge(candidate)
    },

    goToEdit({expand, isExpanded}) {
      if(this.$refs.expand) {
        if(this.$refs.expand.model && this.$refs.expand.model.editing) {
          let goToEdit = () => {
            setTimeout(() => {
              expand(!isExpanded);
            }, 0);
          } ;
          this.$refs.expand.goBackHandler(goToEdit);
          return;
        }
      }
      expand(!isExpanded);
    },
  },

}
