import {mapState, mapGetters, mapActions, mapMutations} from "vuex"
import SortModel from '@/app/admin/modules/candidates/core/models/candidatesSortModel'
import expandMixin from "@/app/admin/mixins/expand.mixin"
import AutocompleteRepo from "@/app/admin/modules/candidates/core/models/candidateAutocompleteRepo"
import Repo from "@/services/api/util/auth-repo-local-storage"
import { COLOR_PAGE } from '@/util/const'

export default {

  mixins: [expandMixin],

  props: {
    list: Array,
    loading: Boolean,
    candidatesCount: Object,
    primaryPagination: Object,
    courses: Object,
    pagination: Object
  },

  data: () => ({
    sortModel: null,
    sortBy: [],
    sorting: [],
    activeItems: [],
    autocompleteRepo: new AutocompleteRepo(Repo),
    COLOR_PAGE: COLOR_PAGE.CANDIDATES
  }),

  inject: ['candidatesSvc', 'svc', 'getDefaultPagination'],

  computed: {
    ...mapState('cm',{
      activeCourses: state => state.activeCourses
    }),

    ...mapGetters({
      labels: 'labels/items',
      activeSingleCourse: 'cm/activeSingleCourse'
    }),

    showSelectAll() {
      return this.activeItems.length && this.pagination && this.pagination.count > this.pagination.size
    },
  },

  methods: {
    ...mapActions({
      loadLabels: 'labels/list',
      courseListDetailed: 'cm/courseListDetailed',
    }),

    async onQuickReserve() {
      await this.courseListDetailed()
    },

    initSortModel() {
      this.sortModel = new SortModel(this.$route.query)
    },

    async updateSorting() {
      let data = this.sortModel.getApiData(this.$route.query)
      let ordering
      if(data.ordering.length) ordering = data.ordering
      await this.$router.replace({ query: { ...this.$route.query, ordering: ordering}})
      this.$emit('change:ordering')
    },

    paymentsOpen(data) {
      this.$refs.payments.paymentsOpen(data)
    },

    extendedSelection({item, index}, list) {
      let activeItemsIndex = this.activeItems.map(item => list.findIndex(_item => _item.ID === item.ID))
      let itemUpperToSelectedIndex = -1
      activeItemsIndex.forEach(activeIndex => {
        if ((index > activeIndex) && (itemUpperToSelectedIndex < activeIndex)) itemUpperToSelectedIndex = activeIndex
      })
      if (itemUpperToSelectedIndex < 0) return
      for (let i = itemUpperToSelectedIndex + 1; i < index; i++) {
        this.activeItems.push(list[i])
      }
    },

    onTabsChange() {
      this.clearSelection();
      this.$emit('change:query');
    },

    onChangeCourseBulk() {
      this.clearSelection();
      this.$emit('needToUpdate:courses');
      this.$emit('change:query');
    },

    selectAllClick({clear}) {
      this.$emit('selectAllToggle', clear)
    },

    setActiveItems(items) {
      this.activeItems = items;
    },

    clearSelection() {
      this.activeItems = [];
    },

    loadList() {
      this.$emit('change:query');
    }
  },

  watch: {
    '$route.query': function() {
      this.activeItems = []
    },
  },

  provide: function() {
    return {
      autocompleteRepo: this.autocompleteRepo,
      svc: () => this.candidatesSvc
    }
  },
}
