<script>
  
export default {
  render() {
    return this.$scopedSlots.default
  },

  mounted() {
    this.$emit('mounted')
  }
}
</script>