<template lang="pug">
  .course-page(:class="{'course-page_mobile': isMobile}")
    portal(to="toolbar-title")
      router-link(
        v-if="isMobile && $route.query.course_day"
        :to="{name: $ROUTER_NAMES.COURSES, query: {}}"
      ).show-menu
        v-icon(color="#000") mdi-arrow-left
      toolbar-title Courses

    portal(to="toolbar-end")
      div
        portal-target(name="course-filters")
      CourseAppBarButtons(@needToUpdate="updateData" :activeCity="getActiveCityID" v-if="!isMobile")
    searchWidget(
      v-if="!isMobile"
      placeholder="Search by CBR account, client name, data etc…"
      :disabled="!$route.query.course_day"
      @change="loadData"
    )
      template(#default="{open}" v-if="isMobile")
        v-icon(@click="open") mdi-magnify
    portal(to="toolbar-search" v-else)
      search-mobile(
        :disabled="!$route.query.course_day"
        @change="loadData"
      )
    CMChips(hideAdditionalChips portal-chip-name="courseday-filters-chips")
     
    .course-page__content(:class="{'course-page__content_mobile': isMobile}")
      portal(to="course-appbar")
        app-button(icon iconLarge @click.native="toggle" :class="{'ml-2': !isCourseLeadersAccess}").sidebar-toggle.unselectable
          <i v-if="!showing" class="material-icons">menu</i>
          <i v-else class="material-icons">clear</i>
      course-day-sidebar(
        ref="sidebar"
        @select:course="selectCourse"
        @updated:sidebar="updateData"
        :class="{'course-page__content_with-chips': isFiltersActive, open: showing}"
      )

     
      course-day-body-mobile(
        v-if="isMobile"
        ref="courseDayBody"
        :key="$route.query.course_day || 'default-body'"
        :activeItem="activeItem"
        :list="list"
        :loading="loading"
        :candidatesCount="candidatesCount"
        :candidatesExamCount="candidatesExamCount"
        :primaryPagination="primaryPagination"
        :pagination="pagination"
        :groupedList="groupedList"
        @change="loadData"
        @change:query="loadData"
        @selectAllToggle="toggleSelectAllItems"
        @needToUpdate="updateData"
        @needToUpdate:courses="loadSidebarCoursesList"
        @onRemoveFromGroup="removeFromGroup"
        @click:loadMore="onLoadMoreClick"
      )
      course-day-body(
        v-else
        ref="courseDayBody"
        :key="$route.query.course_day || 'default-body'"
        :activeItem="activeItem"
        :list="list"
        :loading="loading"
        :candidatesCount="candidatesCount"
        :candidatesExamCount="candidatesExamCount"
        :primaryPagination="primaryPagination"
        :pagination="pagination"
        :groupedList="groupedList"
        @change="loadData"
        @change:query="loadData"
        @selectAllToggle="toggleSelectAllItems"
        @needToUpdate="updateData"
        @needToUpdate:courses="loadSidebarCoursesList"
        @onRemoveFromGroup="removeFromGroup"
      )
        template(v-slot:footer="footerOptions")
          .pagination(v-if="pagination")
            .pagination__more
              load-more-button(:loading="loading" :pagination="pagination" @loadMore="onLoadMoreClick")

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import errorsMixin from '@/mixins/errors.mixin'
import showingMixin from '@/mixins/showing.mixin'
import Svc from '../course_managment/core/cm-service'
import CandidatesService from './core/candidates.new.service'
import CandidatesActionsService from '../candidates/core/candidates-actions-service'
import { SIDEBAR_PAGE_QUERY, COURSE_DAY_PAGES } from './core/courseDay-const'
import CandidatesCount from '@/app/admin/modules/candidates/core/models/candidatesCountModel'
import CandidateBroadcast from '@/app/admin/modules/candidates/core/models/candidateBroadcast'
import paginationMixin from '@/mixins/pagination.mixin'
import PaginationModel from '../../../../models/paginationModel'
import apiCandidates from '@/services/api/api-candidates'
import JourneyGrouped from './core/courseDayJourneyGrouped'
import JourneyService from './core/journey-service'
import apiJourney from '@/services/api/api-journey'
import examBroadcastMixin from '@/mixins/examBroadcast.mixin'
import permsMixin from "@/mixins/perms.mixin"
import { LOAD_DEFAULT_CITY_ID } from '@/util/const'
import { DDMMYYYYFormatting } from '@/util'
import appButton from '@/components/global/actions/BaseButton.vue'
import CourseDayBodyMobile from "./components/mobile/CourseDayBodyMobile.vue"
import { isMobile } from '@/util'
import SearchMobile from "./components/mobile/SearchMobile.vue"


export default {
  name: 'CoursePage',

  metaInfo() {
    return {
      title: this.courseName,
      titleTemplate: chunk => (chunk ? `${chunk} - Nutheorie CRM` : `Nutheorie CRM`)
    }
  },

  mixins: [errorsMixin, permsMixin, showingMixin, paginationMixin, examBroadcastMixin],

  data: () => ({
    candidatesSvc: new CandidatesService(apiCandidates),
    candidatesActionsSvc: new CandidatesActionsService(),
    journeySvc: new JourneyService(apiJourney),
    loading: false,
    list: [],
    groupedList: {},
    fullListIds: [],
    journeys: [],
    svc: new Svc(),
    courses: null,
    candidatesCount: new CandidatesCount(apiCandidates),
    candidatesExamCount: null,
    pagination: null,
    primaryPagination: null,
    examBroadcast: null,
    SIDEBAR_PAGE_QUERY,
    isMobile: isMobile()
  }),

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    }),

    ...mapState('cm', {
      activeCourses: state => state.activeCourses
    }),

    ...mapState('courseDay', {
      activeItem: state => state.activeItem
    }),

    isFiltersActive() {
      let filters = ['cbr_user', 'exam_location', 'exam_date_before', 'exam_date_after']
      return Object.keys(this.$route.query).some(filter => filters.includes(filter))
    },

    courseName() {
      if (!this.activeItem) return ''
      let city = this.cities.find(city => city.ID === this.activeItem.city)
      return `${city.name} (${DDMMYYYYFormatting(this.activeItem.date)}) ${this.activeItem.name}`
    },

    getActiveCityID() {
      if (this.activeItem && this.activeItem.city) return this.activeItem.city
      else return LOAD_DEFAULT_CITY_ID
    },
  },

  created() {
    if (this.$route.query.page && this.$route.query.page > 1 || (this.$route.query.page_sidebar && this.$route.query.page_sidebar > 1)) this.setQuery({
      page: 1,
      page_sidebar: 1
    })
    this.setDefaultPagination()
    this.initCandidateBroadcast()
  },

  mounted() {
    
    this.loadCBRUser({cache: true})
    this.loadCandidateColors()
  },

  methods: {
    ...mapActions({
      loadCBRUser: 'cbrUser/list',
      loadCandidateColors: 'crmCandidateColors/colorsList',
      updateActiveItem: 'courseDay/updateActiveItem'
    }),

    ...mapMutations({
      setActiveItem: 'courseDay/setActiveItem'
    }),

    async selectCourse(item) {
      if (!item) return await this.unselectCourse()
      this.setActiveItem(item)
      this.clearFullListIds()
      this.updateShowing(false) // for mobile sidebar navigation
      await this.loadData()
    },

    async unselectCourse() {
      this.clearList()
      this.list = []
      this.groupedList = {}
    },

    clearList() {
      this.candidatesCount = new CandidatesCount()
      this.candidatesExamCount = null
    },

    async loadData() {
      try {
        this.loading = true
        let [candidates, journeys] = await Promise.all([
          this.loadList({query: this.$route.query}),
          this.loadJourneys(this.activeItem.journey_days)
        ])
        this.journeys = journeys.results
        candidates.result = this.listWithJourneys(candidates.results, journeys.results)
        this.list = candidates.results
        let groupedList = new JourneyGrouped(candidates.results).groupedList(journeys.results)
        this.groupedList = this._withoutItemsGroupedList(groupedList)
        this._addItemsToGroupedList(groupedList)
        this.setPrimaryPagination()
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async loadList({query}) {
      try {
        let pageName = this.$route.query.page_name ? this.$route.query.page_name : COURSE_DAY_PAGES.ALL_WILL_COME
        if (!('ordering' in query) || !query.ordering) {
          if (this.isCourseLeadersAccess) query.ordering = 'exam_datetime,exam_location'
          else query.ordering = 'journey_day,exam_datetime,exam_location'
        }
        let {results, pagination, candidates_counts, exams_statistic} = await this.candidatesSvc.candidates({
          size: this.$route.query.size || 50,
          ...query,
          city: undefined // do not load user by city
        }, pageName)
        this.pagination = new PaginationModel(this.setPaginationParams(pagination))
        this.candidatesCount.set(candidates_counts)
        this.candidatesExamCount = exams_statistic
        return Promise.resolve({results, candidates_counts})
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async loadJourneys(journey_day) {
      try {
        if (!journey_day || !journey_day.length) return {
          results: []
        }
        let res = await this.journeySvc.journeys({journey_day})
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    listWithJourneys(list, journeys) {
      return list.map(item => {
        let journey = journeys.find(journeyItem => journeyItem.id === item.journey_day)
        if (journey) item.journey_day = journey
        return item
      })
    },

    _withoutItemsGroupedList(groupedList) {
      let withoutItemsGroupedList = {}
      for (let group in groupedList) {
        withoutItemsGroupedList[group] = {
          label: groupedList[group].label,
          journeyDayID: groupedList[group].journeyDayID,
          sections: groupedList[group].sections,
          items: []
        }
      }
      return withoutItemsGroupedList
    },

    _addItemsToGroupedList(groupedList) {
      for (let group in groupedList) {
        groupedList[group].items.forEach(item => setTimeout(() => this.groupedList[group].items.push(item), 25))
      }
    },

    async loadMore() {
      try {
        this.loading = true
        let res = await this.loadList({query: this.$route.query})
        res.result = this.listWithJourneys(res.results, this.journeys)
        this.list = [...this.list, ...res.result]
        let groupedList = new JourneyGrouped(res.results).groupedList(this.journeys)
        this._addItemsToGroupedList(groupedList)
      } catch (err) {
        this.processError(err, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async toggleSelectAllItems(clear) {
      if (clear) this.$refs.courseDayBody.setActiveItems([]);
      else {
        if (!this.fullListIds.length) {
          await this.loadData()
          await this.getAllCandidatesListIDs();
        }
        this.$refs.courseDayBody.setActiveItems(this.fullListIds);
      }
    },

    async getAllCandidatesListIDs() {
      const page = this.$route.query.page_name ? this.$route.query.page_name : COURSE_DAY_PAGES.ALL_WILL_COME
      if (this.pagination.count > this.pagination.size) {
        try {
          this.fullListIds = await this.candidatesSvc.getFullListIDs({ids_list: true, ...this.$route.query}, page);
        } catch (error) {
          console.log(error)
          this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
        }
      } else this.fullListIds = [];
    },

    // pagination start
    setPrimaryPagination() {
      this.primaryPagination = {
        page: this.pagination.page,
        size: this.pagination.size
      };
    },

    async onLoadMoreClick() {
      this.pagination.loadMore()
      await this.setPaginationQuery()
      await this.loadMore()
    },
    // pagination end

    async initCandidateBroadcast() {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.broadcast = new CandidateBroadcast(null, tokens.access)
      this.broadcast.startListen(this.getBroadcastData, null, this._setCandidateDataFromWs)
    },

    _setCandidateDataFromWs(candidate, list) {
      let item = list.find(item => item.ID === candidate.id)
      if (!item) return
      for (let field in candidate) {
        if (field === 'exam') {
          continue
        }
        if (!item._apiAttributes[field]) continue
        item[item._apiAttributes[field]] = candidate[field]
      }
      if (this.disabledModel) this.disabledModel.checkCBRStatus(item.status)
      item.animation = true;
      setTimeout(() => {
        item.animation = false;
      }, 1000);
    },

    getBroadcastData() {
      return this.list
    },

    async updateData(selectItemsUpdate) {
      if (selectItemsUpdate) await this.toggleSelectAllItems(true)

      this.loadSidebarCoursesList()

      if (this.activeItem) {
        await this.updateActiveItem()
        await this.loadData()
      }
    },

    loadSidebarCoursesList() {
      this.$refs.sidebar.loadData({more: false}, {[SIDEBAR_PAGE_QUERY]: 1})
    },

    clearFullListIds() {
      this.fullListIds = [];
    },

    updateField(id, field, value) {
      let item = this.list.find(_item => _item.ID === id)
      if (!item) return
      item[field] = value
      let groupedItemIndex = this.groupedList[item.journey_day.id].items.findIndex(_item => _item.ID === item.ID)
      this.groupedList[item.journey_day.id].items[groupedItemIndex].exam.journey = value
    },

    async setQuery(query) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...query}})
    },

    removeFromGroup({item, index}) {
      let page = COURSE_DAY_PAGES.ALL_WILL_COME
      let currentPage = this.$route.query.page_name || page
      if (currentPage !== page) {
        this.candidatesCount.allWillCome++;
        this.candidatesCount.allWillNotCome--;
      } else {
        this.candidatesCount.allWillCome--;
        this.candidatesCount.allWillNotCome++;
      }
      this.$refs.sidebar.updateActiveItemCounts({
        will_come_candidates_count: this.candidatesCount.allWillCome,
        will_not_come_candidates_count: this.candidatesCount.allWillNotCome
      })
      this.groupedList[item.journey_day.id].items.splice(index, 1)
    }
  },

  beforeRouteLeave(to, from, next) {
    this.examBroadcast.close()
    this.broadcast.close()
    this.clearFullListIds()
    next()
  },

  provide: function () {
    return {
      svc: this.svc,
      courseSvc: this.courseSvc,
      candidatesSvc: this.candidatesSvc,
      candidatesActionsSvc: this.candidatesActionsSvc,
      getDefaultPagination: this.getDefaultPagination,
      updatePageSize: this.updatePageSize,
      setQuery: this.setQuery,
      updateField: this.updateField,
      activeCourse: () => this.activeItem
    }
  },

  watch: {
    '$route.query.course_day': {
      handler: function () {
        console.log(this.isMobile)
        if(!this.$route.query.course_day && this.isMobile) this.toggle()
      },
      immediate: true
    }
  },

  components: {
    CourseDayBodyMobile,
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    searchWidget: () => import('@/components/widget/SearchWidget.vue'),
    courseDaySidebar: () => import('@/app/admin/modules/course_day/courseDaySidebar.vue'),
    courseDayBody: () => import('@/app/admin/modules/course_day/CourseDayBody.vue'),
    CMChips: () => import('@/app/admin/modules/course_managment/components/CMChips.vue'),
    CourseAppBarButtons: () => import('@/app/admin/modules/course_day/components/sidebar/CourseAppBarButtons.vue'),
    loadMoreButton: () => import('@/components/widget/LoadMoreButton.vue'),
    SearchMobile,
    appButton
  }
}
</script>

<style lang="scss">
@import "~material-design-icons-iconfont/dist/material-design-icons.css";

.page-courses {
  .course-page {
    &__content {
      display: flex;
      overflow: hidden;
      height: calc(100vh - 65px);
      background-color: #FEFFFF;
      transition: all 0.25s ease-in-out;

      &_with-chips {
        height: calc(100vh - 65px - 48px);
      }
    }
    &_mobile {
      background-color: #F4F7F9;
      .course-page__content {
        overflow: initial;
        background-color: #F4F7F9;
      }
    }
  }

  .pagination {
    position: relative;
    width: 100%;
  }
}

//.course-page {
//  @include media("<=tablet") {
//    .v-tabs-slider-wrapper {
//      display: none;
//    }
//    .v-tab.v-tab--active {
//      &:after {
//        content: '';
//        position: absolute;
//        bottom: -1px;
//        background: $primary-color;
//        width: 100%;
//        height: 3px;
//      }
//    }
//  }
//}

</style>

<style lang="scss" scoped>
.sidebar-toggle {
  display: none;

  @include media("<=tablet") {
    display: flex;
  }
}

.show-menu {
  margin-right: 12px;
  text-decoration: unset;
}

</style>
