<template lang="pug">
v-bottom-sheet(v-bind="$attrs" transition="dialog-bottom-transition")
  template(#activator="{on}")
    slot(name="activator")

  .sheet-body
    .sheet-body__top
      .sheet-body__title.appmodal-title {{ title }}
      .sheet-body__descr.app-modal-desr 
        slot(name="description")
    .sheet-body__main
      slot
    .sheet-body__action
      slot(name="actions")
</template>

<script>
export default {
  props: {
    title: String
  }
}</script>
<style lang="scss" scoped>
.sheet-body {
  border-radius: 16px 16px 0 0;
  background: #FFFFFF;
  font-family: $font;

  &__top {
    padding: 16px;
    padding-bottom: 24px;
    border-radius: 16px 16px 0 0;
    background: rgba(#ECECEC, 0.24);
    text-align: center;
  }
  &__title {
    padding-top: 44px;
  }
  &__descr {
    color: #343A3D;
  }
  &__action {
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-left: 16px;
  }
}

</style>