<template lang="pug">
.student-card(:style="{'background-color': selectedColor}")
  .student-card__main(:style="{'background-color': studentColor}" v-longpress="onTouch")
    .student-card__info
      .student-card__avatar
        upload-photo(:item="item")
        div.d-flex.justify-center.sudent-card__number {{ positionInList }}
      .student-card__info-text
        h3.student-card__title {{ item.candidateName }}
        .student-card__text {{ item.exam.location }}
        .student-card__text {{ item.exam.examDate }}
        .student-card__text(v-if="item.result") {{ item.result.reservation_code}} 
        v-chip(
          v-if="item.notes"
          color="rgba(255, 184, 15, 0.16)"
          small
        ).mt-1
          v-icon(:size="12" color="#FFB80F").mr-1 mdi-note-text-outline
          | {{ item.notes | clipString(30) }}
    result-service-provider
      .student-card__result 
        .student-card__product(v-if="isProductVE")
          v-icon(size="18" color="primary") mdi-clock-plus-outline
        result-info(v-if="!this.item.isMijnReservation" :reservedExam="item.result" :isMijnReservation="this.item.isMijnReservation" :candidateName="item.candidateName")
        mijn-info(v-if="this.item.isMijnReservation" :reservedExam="item.result" :candidateName="item.candidateName")
  .student-card__footer
    presentie-status(
      :journey="item.journey_day.id"
      :presentie-status="item.presentie_status"
      :exam="item.exam.id"
      :candidateID="item.ID"
      :class="{'student-card-item_active': candidateCame}"
      v-slot="{togglePresentie}"
    ).student-card-item.student-card-item_selectable
      v-icon(v-if="!candidateCame" color="#fff" @click="togglePresentie") mdi-account-outline
      v-icon(v-else color="#fff" @click="togglePresentie") mdi-account-check-outline
    
    showingProvider(v-slot="showingProvider")
      app-bottom-sheet(v-model="showingProvider.showing" :title="item.candidateName")
        template(#activator)
          .student-card-item(@click="showingProvider.toggle")
            v-icon mdi-phone-outline
        template(#description)
          span
            | {{ item.exam.location }}
            br
            | {{ item.exam.examDate }}
        template(#default)
          .phone 
            | {{ item.candidateTel }}
            v-icon(@click="copyMobile(item.candidateTel)").ml-3 mdi-content-copy
        template(#actions)
          a(:href="`tel:${item.candidateTel}`").button-link CALL
    showingProvider(v-slot="showingProvider")
      inform-candidate(
        :ID="item.exam.id" 
        :messageSentCount="item.exam.message_sent_count"
        v-slot="informCandidate"
        @send:start="showingProvider.toggle"
      )
        app-bottom-sheet(v-model="showingProvider.showing" :title="item.candidateName")
          template(#activator)
            .student-card-item(@click="showingProvider.toggle")
               v-badge(right :color="informCandidate.countColor" overlap).result-badge
                template(v-slot:badge)
                  span {{ item.exam.message_sent_count }}
                v-icon mdi-email-arrow-right-outline 
           
          template(#description)
            span
              | {{ item.exam.location }}
              br
              | {{ item.exam.examDate }}
          template(#default)
            div.student-inform 
              | Are you sure you want to send Afwezigen email and SMS?
              .student-inform__count {{ item.exam.message_sent_count }}
          template(#actions)
            .d-flex
              app-btn(color="negative" v2 font-size="16px" block @click.native="showingProvider.toggle").mr-3 CANCEL
              app-btn(color="primary" v2 font-size="16px" block @click.native="informCandidate.send") SEND
    .student-card-item
      candidate-color(:activeItems="[item]")
    showingProvider(v-slot="showingProvider")
      .student-card-item
        candidates-service
          candidate-note(:value="item.notes" :ID="item.ID" 
            @saved="showingProvider.toggle"
            v-slot="{save, input, inputChanged, updateInput, startEditing}"
          )
            full-screen-modal(
              v-model="showingProvider.showing"
              @input="showingProvider.updateShowing"
              title="Add note"
            )
              template(#activator)
                v-icon(@click.native="() => {showingProvider.toggle(); startEditing()} ") mdi-note-outline
              div 
                .appmodal-title {{ item.candidateName }}
                div.appmodal-descr
                  | {{ item.exam.location }}
                  br
                  | {{ item.exam.examDate }}
                app-textarea(
                  :value="input" @input="updateInput" hide-details placeholer="notes"
                )
                app-btn(color="primary" v2 font-size="16px" block @click.native="save" :disabled="!inputChanged") SEND
         
    student-info(:item="item")
  
</template>

<script>
import AppBtn from '@/components/global/actions/BaseButton.vue'
import BaseDialog from '@/components/global/actions/BaseDialog'
import ShowingProvider from '@/components/renderless/ShowingProvider.vue'
import ResultInfo from "./CourseDayResultInfo.vue"
import MijnInfo from "./CourseDayMijnResultInfo.vue"
import PresentieStatus from '../journey/CourseDayPresentieStatus.vue'
import {PRESENTIE_STATUS} from '../../core/CourseDayConst'
import AppBottomSheet from "@/components/global/BaseBottomSheet.vue"
import informCandidate from '../../../results/components/ResultsInformCandidate.vue'
import CandidateNote from '@/app/admin/modules/candidates/components/candidates/CandidatesNote.vue'
import FullScreenModal from "./CourseFullScreenModal.vue"
import UploadPhoto from "./CourseDayUploadPhotoMobile.vue"
import CandidateColor from "./CourseDayColors.vue"
import colorMixin from '@/mixins/color.mixin'
import copyMixin from '@/mixins/copy.mixin'
import { COURSE_DAY_SIDEBAR_TABS } from '../../core/courseDay-const'
import { PRODUCT } from '@/util/const'


export default {
  props: {
    item: Object,
    index: Number,
    primaryPagination: Object,
    selected: Array
  },
  data: () => ({
  }),
  mixins: [colorMixin, copyMixin],
  computed: {
    candidateCame() {
      return [PRESENTIE_STATUS.CAME, PRESENTIE_STATUS.PARTIALLY_CAME].includes(this.item.presentie_status)
    },

    positionInList() {
      return (this.index + 1) + (this.primaryPagination.page - 1) * this.primaryPagination.size;
    },

    isSelected() {
      return !!this.selected.find(item => item.ID === this.item.ID)
    },

    selectedColor() {
      return this.isSelected ? '#E3F4FF' : ''
    },

    studentColor() {
      return this.isSelected ? '' : this.candidateColor
    },
    isPastTab() {
      return this.$route.query.tab_name === COURSE_DAY_SIDEBAR_TABS.PAST
    },

    isProductVE() {
      let product = this.isPastTab ? this.item.exam.productName : this.item.productName
      return product === PRODUCT.BTH_VE
    }
  },

  methods: {
    onTouch() {
      this.$emit('click:info')
    }
  },
  components: {
    AppBtn,
    BaseDialog,
    ShowingProvider,
    ResultInfo,
    MijnInfo,
    PresentieStatus,
    AppBottomSheet,
    informCandidate,
    CandidateNote,
    FullScreenModal,
    showingProvider: () => import('@/components/renderless/ShowingProvider.vue'),
    appTextarea: () => import('@/components/global/actions/BaseTextarea.vue'),
    studentInfo: () => import('./CourseDayStudentCardInfo.vue'),
    candidatesService: () => import('@/app/admin/modules/candidates/components/CandidatesProvider.vue'),
    resultServiceProvider: () => import('@/app/admin/modules/results/components/ResultsServiceProvider.vue'),
    CandidateColor,
    UploadPhoto

  }
}
</script>

<style lang="scss" scoped>
.student-card {
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(22, 22, 22, 0.08);
  font-family: $font;
  &:not(:first-child) {
    margin-top: 8px;
  }
  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-left: 16px;
    user-select: none;
  }
  &__info {
    display : flex;
    &-text {
      margin-left: 8px;
    }
  }
  &__number {
    margin-top: 2px;
    font-size: 12px;
  }
  &__avatar {
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
  &__title {
    margin-bottom: 2px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  &__text {
    color: #343A3D;
    font-size: 12px;
    line-height: 14px;
  }
  &__footer {
    display: flex;
    align-items: center;
    width: 100%;
    background: rgba(#D1D1D1, 0.08);
  }
  &__result {
    display: flex;
    align-items: center;
  }
  &__product {
    width: 34px;
    height: 34px;
    border-radius: 4px;
    background: #E3F4FF;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-self: center;
  }
}

.student-card-item {
  display: inline-flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  height: 48px;
  &_selectable {
    border-radius: 0 24px 0 0;
    background: rgba(#ECECEC, 0.56);
    background-color: #9e9e9e;
  }
  &_active {
    background-color: $success-cl;
  }

}



.phone {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 72px;
  padding-bottom: 72px;
  color: #232627;
  font-weight: 800;
  font-size: 32px;
}

.button-link {
  position: relative;
  padding: 8px;
  min-width: 80px;
  outline: none;
  border-radius: 4px;
  color: #000000;
  font-weight: 500;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  background-color: #4E7FED;
  width: 100%;
  color: #fff;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}

.student-inform {
  position: relative;
  padding: 24px 16px;
  font-size: 16px;
  &__count {
    position: absolute;
    top: -20px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 56px;
    background: #FFFFFF;
    box-shadow: 0 0 24px rgba(22, 22, 22, 0.16);
    color: #5C6E77;
    font-weight: 700;
    font-size: 16px;
  }
}


</style>