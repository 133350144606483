<template lang="pug">
upload-photo(
  :ID="item.ID" 
  :course-photo="item.course_photo" 
  :fullname="item.candidateName"
  ref="uploadPhoto"
  @loading:start="updateConsent"
  @remove:photo="removePhoto"
)
  template(#image="{toggle}" v-if="item.course_photo")
    slot(name="image" :toggle="toggle")
      .avatar-wrapper(@click="toggle")
        
        img(:src="item.course_photo")
  template(#activator="{hasCoursePhoto}")
    slot
      .avatar-wrapper
        v-icon(:size="16" v-if="!hasCoursePhoto") mdi-image-plus-outline
        img(v-else :src="item.course_photo")
  template(#default="{showing, updateShowing, removePhoto, toggle, showFileInfoWithoutToggle, previewImage, loading, loadFile, openFileLoader}")
    full-screen-modal(
      v-model="showing"
      @input="updateShowing"
      
      :title="title"
      no-body-spacing
    )
      template(#actions)
        .d-flex
          base-input-file(@input="showFileInfoWithoutToggle")
            template(#icon)
              v-icon mdi-upload-outline
          v-icon.ml-2(color="error" @click="removePhoto") mdi-close
      div.upload-photo
        base-preloader(v-if="loading && !previewImage")
        img(:src="previewImage || item.course_photo").preview-image
        <!-- p {{ previewImage }} -->
        mounted-renderless(@mounted="actualizeConsent")
        .sheet-body
          .sheet-body__top
            .sheet-body__title.appmodal-title {{ item.candidateName }}
          .sheet-body__main
            app-checkbox(
              hide-details
              v-model="consent"
              :value="true"
              label="The student agrees to share his photo on social networks" id="upload_photo_consent"
            ) T
          .sheet-body__action 
            app-btn(color="primary" v2 font-size="16px" block 
              @click.native="isPhotoChanged(previewImage, loadFile, toggle)" :loading="loading"
            ) SAVE

   
  <!-- img(src="@/assets/img/student-avatar.jpg") -->
</template>

<script>
import UploadPhoto from "@/app/admin/modules/course_day/components/actions/UploadPhoto.vue"
import FullScreenModal from "./CourseFullScreenModal.vue"
import AppBottomSheet from "@/components/global/BaseBottomSheet.vue"
import appCheckbox from '@/components/global/Checkbox.vue'
import MountedRenderless from '@/components/renderless/MountedRenderless.vue'
import AppBtn from '@/components/global/actions/BaseButton.vue'
import CandidateService from '@/app/admin/modules/candidates/core/candidates-service'
import apiCandidates from '@/services/api/api-candidates'
import { title } from "process"


export default {
  props: {item: Object},
  inject: ['updateField'],
  data: () => ({
    consent: false
  }),

  computed: {
    title() {
      return this.item.course_photo ? 'Edit photo': 'Add photo'
    },
  },

  methods: {
    actualizeConsent() {
      this.consent = this.item.course_photo_consent
    },

    isPhotoChanged(photo, load, toggle) {
      let changed = photo ? true : false
      if(changed) load()
      else {
        toggle()
        this.updateConsent()
       
      } 
    },

    async updateConsent() {
      if(this.consent === this.item.course_photo_consent) return
      await new CandidateService(apiCandidates).toggleCoursePhotoConsent(this.item.ID)

    },

    clear() {
      this.$refs.uploadPhoto.clear()
    },

    removePhoto() {
      this.updateField(this.item.ID, 'course_photo', null)
    }
  },
  components: {
    UploadPhoto, FullScreenModal, AppBottomSheet, 
    BasePreloader: () => import("@/components/global/BasePreloader.vue"),
    appCheckbox,
    MountedRenderless,
    AppBtn,
    baseInputFile: () =>
      import("@/components/global/actions/BaseInputFile.vue"),
  }
}
</script>

<style lang="scss" scoped>
.sheet-body {
  position: absolute;
  bottom: 0;
  margin-top: auto;
  border-radius: 16px 16px 0 0;
  background: #FFFFFF;
  font-family: $font;

  &__top {
    padding: 16px;
    padding-bottom: 24px;
    border-radius: 16px 16px 0 0;
    background: rgba(#ECECEC, 0.24);
    text-align: center;
  }
  &__main {
    padding: 16px;
  }

  &__descr {
    color: #343A3D;
  }
  &__action {
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-left: 16px;
    border-top: 1px solid #ECECEC;
  }
}

.preview-image {
  flex-grow: 1;
  max-height: calc(100vh - 48px);
  width: 100%;
  height: 100%;;
}

.upload-photo {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(#007AFF, 0.16);
  font-size: 16px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  > i {
    color: #007AFF;
  }
}

</style>