<template lang="pug">
  span
    slot(:loading="loading" :togglePresentie="togglePresentie" :candidateCame="candidateCame")
      v-checkbox(
        :input-value="candidateCame"
        :disabled="loading"
        @click.prevent="togglePresentie"
      )

    base-dialog(
      v-model="showing"
      width="320px"
      title="Remove from presentie?"
    )
      template(#dialog-body)
        div.presentie-confirm Candidate will be remove from presentie.

      template(#dialog-footer)
        app-button(color="#f4f7f9" @click.native="toggle" hide-shadow).mr-3
          span.text-label Close
        app-button(color="primary" @click.native="removeFromPresentie") Ok
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import JourneyService from '../../core/journey-service'
import apiJourney from '@/services/api/api-journey'
import BaseDialog from '@/components/global/actions/BaseDialog.vue'
import showingMixin from '@/mixins/showing.mixin'

const PRESENTIE_STATUS = {
  NOT_CAME: 'not_came',
  CAME: 'came',
  PARTIALLY_CAME: 'partially_came'
}

export default {
  name: 'PresentieStatus',

  inject: ['svc', 'updateField'],

  mixins: [errorsMixin, showingMixin],

  props: {
    journey: {
      required: true,
      type: Number
    },

    presentieStatus: {
      required: true,
      type: String,
      validator: function (value) {
        return [PRESENTIE_STATUS.CAME, PRESENTIE_STATUS.PARTIALLY_CAME, PRESENTIE_STATUS.NOT_CAME].includes(value)
      }
    },
    exam: Number,
    candidateID: Number
  },

  data: () => ({
    loading: false
  }),

  computed: {
    candidateCame() {
      return [PRESENTIE_STATUS.CAME, PRESENTIE_STATUS.PARTIALLY_CAME].includes(this.presentieStatus)
    }
  },

  methods: {
    togglePresentie() {
      if (this.candidateCame) this.updateShowing(true)
      else {
        this.updatePresentie({ needRemove: false })
        this.updateField(this.candidateID, 'presentie_status', PRESENTIE_STATUS.CAME)
      }
    },

    async updatePresentie(payload) {
      try {
        this.loading = true
        await new JourneyService(apiJourney).setPresentie(this.journey, this.exam, payload.needRemove)
        this.$notify({ type: 'success', text: 'Presentie status updated' })
      } catch (error) {
        this.processError(error, { redirectName: this.$ROUTER_NAMES.LOGIN_CRM })
      } finally {
        this.loading = false
      }
    },

    removeFromPresentie() {
      this.updateShowing(false)
      this.updateField(this.candidateID, 'presentie_status', PRESENTIE_STATUS.NOT_CAME)
      this.updatePresentie({ needRemove: true })
    },
  },

  components: {
    BaseDialog,
    appButton: () => import('@/components/global/actions/BaseButton.vue')
  }
}
</script>

<style lang="scss">
.presentie-confirm {
  padding: 15px;
}

</style>
