import AutocompleteBase from "@/models/autocompleteBase"

export default class CandidateAutocompleteRepoLocalStorage extends AutocompleteBase {
  constructor(Repo) {
    super(Repo)
    this.fields = {
      examDate: [],
      timeFrom: [],
      timeTo: []
    }
  }
}