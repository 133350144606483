<template lang="pug">
.filter-section
  .filter-section__title Sort by:
  .sort-item(v-for="sortItem in sortingList" :key="sortItem.value" @click="sort(sortItem.value)")
    span {{ sortItem.label }}
    v-icon.sort-icon(
      v-if="sortBy.includes(sortItem.value)"
      :class="{'desc': sortDesc[0]}"
    ) mdi-arrow-up
</template>

<script>
import sortingMixin from "@/mixins/table/sorting.mixin";
  
export default {
  props: {sortBy: Array, sortDesc: Array, sortingList: Array},
  mixins: [sortingMixin],
}
</script>
  