import { render, staticRenderFns } from "./CourseFullScreenModal.vue?vue&type=template&id=2411537d&scoped=true&lang=pug&"
import script from "./CourseFullScreenModal.vue?vue&type=script&lang=js&"
export * from "./CourseFullScreenModal.vue?vue&type=script&lang=js&"
import style0 from "./CourseFullScreenModal.vue?vue&type=style&index=0&id=2411537d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2411537d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDialog,VIcon})
