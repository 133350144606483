<template lang="pug">
v-dialog(
  :value="value"
  @input="$emit('input', $event)"
  fullscreen
  hide-overlay
  scrollable
  :disabled="disabled"
)
  template(#activator="{on}")
    slot(name="activator" :on="on")
  div.fullscreen-wrapper
    app-toolbar(:transparent="transparentHeader" :color="toolbarColor")
      template(#before-title)
        slot(name="before-title")
          v-icon( size="18" @click="$emit('input', false)") mdi-arrow-left
      span(slot="title") {{ title }}
      slot(name="actions")
    .fullscreen-body(:class="{'fullscreen-body_no-spacing': noBodySpacing}")
      slot
</template>

<script>
import Toolbar from "@/components/global/toolbar/BaseToolbar.vue"

export default {
  props: {
    value: {
      required: true,
      type: Boolean
    },
    title: {
      type: String
    },
    transparentHeader: Boolean,
    noBodySpacing: Boolean,
    toolbarColor: String,
    disabled: Boolean
  },
  components: {
    AppToolbar: Toolbar
  }
}
</script>

<style lang="scss" scoped>
  .fullscreen-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #F4F7F9;
  }
  .fullscreen-body {
    flex-grow: 1;
    padding: 16px;
    &_no-spacing {
      padding: 0;
    }
  }

</style>