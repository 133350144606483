<template lang="pug">
div
  slot(:loading="loading" :download="download")
    v-btn(
      v-if="hasCoursePhoto"
      color="primary"
      outlined
      small
      :disabled="loading"
      @click="download"
    ).cd-actions-item Download course photo
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin';

export default {
  name: 'CoursePhotoDownload',

  mixins: [errorsMixin],

  props: {
    courseID: { type: Number, required: true },
    coursePhoto: { type: String, default: '' },
    hasActiveItems: { type: Boolean, default: false },
    disabled: Boolean
  },

  computed: {
    hasCoursePhoto() {
      return !!(this.coursePhoto || this.hasPhoto);
    },

    getCoursePhoto() {
      if (this.hasPhoto) return this.hasPhoto
      if (this.coursePhoto) return this.coursePhoto
      return ''
    }
  },

  data: () => ({
    loading: false,
    hasPhoto: null,
  }),

  methods: {

    async download() {
      const a = document.createElement("a");
      a.href = await this.toDataURL(this.getCoursePhoto);
      let index = this.getCoursePhoto.lastIndexOf("/") + 1;
      let filename = this.getCoursePhoto.substr(index);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); 
    },

    toDataURL(url) {
      return fetch(url).then((response) => {
        return response.blob();
      }).then(blob => {
        return URL.createObjectURL(blob);
      }).catch(err => {
        console.log(err)
      });
    },

  },
}
</script>
