var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('upload-photo',{ref:"uploadPhoto",attrs:{"ID":_vm.item.ID,"course-photo":_vm.item.course_photo,"fullname":_vm.item.candidateName},on:{"loading:start":_vm.updateConsent,"remove:photo":_vm.removePhoto},scopedSlots:_vm._u([(_vm.item.course_photo)?{key:"image",fn:function(ref){
var toggle = ref.toggle;
return [_vm._t("image",[_c('div',{staticClass:"avatar-wrapper",on:{"click":toggle}},[_c('img',{attrs:{"src":_vm.item.course_photo}})])],{"toggle":toggle})]}}:null,{key:"activator",fn:function(ref){
var hasCoursePhoto = ref.hasCoursePhoto;
return [_vm._t("default",[_c('div',{staticClass:"avatar-wrapper"},[(!hasCoursePhoto)?_c('v-icon',{attrs:{"size":16}},[_vm._v("mdi-image-plus-outline")]):_c('img',{attrs:{"src":_vm.item.course_photo}})],1)])]}},{key:"default",fn:function(ref){
var showing = ref.showing;
var updateShowing = ref.updateShowing;
var removePhoto = ref.removePhoto;
var toggle = ref.toggle;
var showFileInfoWithoutToggle = ref.showFileInfoWithoutToggle;
var previewImage = ref.previewImage;
var loading = ref.loading;
var loadFile = ref.loadFile;
var openFileLoader = ref.openFileLoader;
return [_c('full-screen-modal',{attrs:{"title":_vm.title,"no-body-spacing":""},on:{"input":updateShowing},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('base-input-file',{on:{"input":showFileInfoWithoutToggle},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-upload-outline")])]},proxy:true}],null,true)}),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"error"},on:{"click":removePhoto}},[_vm._v("mdi-close")])],1)]},proxy:true}],null,true),model:{value:(showing),callback:function ($$v) {showing=$$v},expression:"showing"}},[_c('div',{staticClass:"upload-photo"},[(loading && !previewImage)?_c('base-preloader'):_vm._e(),_c('img',{staticClass:"preview-image",attrs:{"src":previewImage || _vm.item.course_photo}}),_c('mounted-renderless',{on:{"mounted":_vm.actualizeConsent}}),_c('div',{staticClass:"sheet-body"},[_c('div',{staticClass:"sheet-body__top"},[_c('div',{staticClass:"sheet-body__title appmodal-title"},[_vm._v(_vm._s(_vm.item.candidateName))])]),_c('div',{staticClass:"sheet-body__main"},[_c('app-checkbox',{attrs:{"hide-details":"","value":true,"label":"The student agrees to share his photo on social networks","id":"upload_photo_consent"},model:{value:(_vm.consent),callback:function ($$v) {_vm.consent=$$v},expression:"consent"}},[_vm._v("T")])],1),_c('div',{staticClass:"sheet-body__action"},[_c('app-btn',{attrs:{"color":"primary","v2":"","font-size":"16px","block":"","loading":loading},nativeOn:{"click":function($event){return _vm.isPhotoChanged(previewImage, loadFile, toggle)}}},[_vm._v("SAVE")])],1)])],1)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }