<template lang="pug">
  .toolbar(:class="{'toolbar_transparent': transparent}" :style="{'background-color': color}")
    .toolbar__wrapper
      div
        .toolbar__title-wrapper(@click="$emit('click:title')")
          slot(name="before-title")
          .toolbar__title
            slot(name="title")
      .toolbar__actions
        slot
</template>

<script>
export default {
  name: 'AppToolbar',
  props: {
    transparent: Boolean,
    color: String
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  align-items: center;
  min-height: 44px;
  background-color: #FFFFFF;
  box-shadow: 0 0 8px rgba(22, 22, 22, 0.16);
  font-family: $font;
  &_transparent {
    background-color: transparent;
    box-shadow: unset;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
    height: 100%;
  }

  &__search {
    flex: 1;
  }

  &__actions {
    margin-left: auto;
  }

  &__title {
    color: #232627;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    &-wrapper {
      display : inline-flex;
      align-items: center;
      .v-icon {
        margin-right: 16px;
        color: #232627;
      }
    }
  }
}

</style>
