<script>
export default {
  render() {
    return this.$scopedSlots.default({
      updateShowing: this.updateShowing,
      toggle: this.toggle,
      showing: this.showing
    })
  },

  data: () => ({
    showing: false
  }),

  methods: {
    toggle() {
      this.showing = !this.showing
    },

    updateShowing(val) {
      this.showing = val
    }
  },

  watch: {
    showing() {
      this.$emit('change', this.showing)
    }
  }
}
</script>