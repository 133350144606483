export default class CandidateAutocompleteRepoLocalStorage {
  constructor(Repo) {
    this.repo = new Repo();
    this.saveFieldName = 'autocompleteFields';
    this.maxLength = 6;
    this.fields = {};
  }

  save(field, value) {
    let optionsByFields = this.getFields(), data = [value], options;
    if (optionsByFields && field in optionsByFields) options = this.getOptionsFilteredByMaxLength(optionsByFields[field]);
    let updateAutocompleteField = {
      [field]: this.getValueToCurrentOptions(data, options, value)
    };
    optionsByFields = {...optionsByFields, ...updateAutocompleteField};
    this.repo.store(this.saveFieldName, optionsByFields);
  }

  getFields() {
    return this.repo.get(this.saveFieldName);
  }

  getOptionsFilteredByMaxLength(options) {
    let filteredOptions = options.splice(0, this.maxLength).filter(option => option);
    return filteredOptions;
  }

  getValueToCurrentOptions(data, options, value) {
    if (!options) return data;
    let valIndex = options.findIndex(item => item === value);
    if (valIndex >= 0) options.splice(valIndex, 1);
    return [...data, ...options];
  }

  loadDataByFields() {
    let fields = this.getFields();
    if (!fields) return;
    if (!(Object.keys(fields).length)) return;
    for (let field in this.fields) {
      this.fields[field] = fields[field];
    }
  }
}